import { z } from "zod"

import type { GID } from "@unlikelystudio/commerce-connector"

import type { Nullish } from "~/@types/generics"
import { handleZodSafeParse } from "~/lib/zod/utils/handle-zod-safe-parse"

// Get from <https://shopify.dev/api/usage/gids#global-id-examples>
const GlobalIDNames = [
  "Collection",
  "Customer",
  "DeliveryCarrierService",
  "DeliveryLocationGroup",
  "DeliveryProfile",
  "DeliveryZone",
  "DraftOrder",
  "DraftOrderLineItem",
  "Duty",
  "Fulfillment",
  "FulfillmentEvent",
  "FulfillmentService",
  "InventoryItem",
  "InventoryLevel",
  "LineItem",
  "Location",
  "MarketingEvent",
  "MediaImage",
  "Metafield",
  "OnlineStoreArticle",
  "OnlineStoreBlog",
  "OnlineStorePage",
  "Order",
  "OrderTransaction",
  "Product",
  "ProductImage",
  "ProductVariant",
  "Refund",
  "Shop",
  "StaffMember",
  "Theme",
  "Variant",
  "Article",
] as const

export type TGlobalIDName = (typeof GlobalIDNames)[number]

export const getPropertyFromGID = (id: string): TGlobalIDName | null => {
  const REGEX = /gid:\/\/shopify\/([a-zA-Z]+)\/\d+/
  const matches = id.match(REGEX)
  return matches && matches[1] && GlobalIDNames.includes(matches[1]) ? (matches[1] as TGlobalIDName) : null
}

export function getGIDFromReferenceId(id: number | string, property: TGlobalIDName): GID {
  const processedID = typeof id === "number" ? id : parseInt(id)
  return `gid://shopify/${property}/${processedID}`
}

export const getReferenceIdFromGID = (gid: Nullish<string>) => {
  if (!gid) {
    return null
  }

  const splitGid = gid.split("/")
  const id = splitGid[splitGid.length - 1]?.split("?")[0]
  return id ? Number(id) : null
}

// decodeBase64 string utf8
export const getBase64CursorFromId = (id: Nullish<number>, value: number) => {
  if (!id) {
    return null
  }

  const parsedCursor = handleZodSafeParse(
    z.object({
      last_id: z.number(),
      last_value: z.number(),
    }),
    {
      last_id: id,
      last_value: value,
    },
    { errorContext: "getBase64CursorFromId" }
  )

  const encodedCursor = parsedCursor ? Buffer.from(JSON.stringify(parsedCursor)).toString("base64") : null

  return encodedCursor
}

export type ShopifyQueryConnective = "OR" | "AND"
export function fromGidsToQueryIds(gids: Nullish<(string | GID)[]>, connective: ShopifyQueryConnective = "OR") {
  return (
    gids?.map((gid) => {
      const id = getReferenceIdFromGID(gid)
      return id ? `id:${id}` : null
    }) ?? []
  )
    .filter(Boolean)
    .join(` ${connective} `)
}

export function sanitizeGid(id: Nullish<GID>): string | null {
  return id?.split("?")?.[0] ?? null
}

export function isGID(value: unknown): value is GID {
  const regex = /^gid:\/\/shopify\/\w+\/\d+$/
  return typeof value === "string" ? regex.test(value ?? "") : false
}
