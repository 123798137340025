import type { formDictionary } from "@unlikelystudio/react-ecommerce-hooks"

import type { ObjectKeys, ObjectValues } from "~/@types/generics"
import { objectValues } from "~/utils/object-values"

export type FormDictionnaryLang = ObjectKeys<typeof formDictionary>
export type FormDictionnaryKey = keyof (typeof formDictionary)[FormDictionnaryLang]

export const ALERT_CODES = {
  EMAIL_SENT: "email_sent",
  SERVER_ERROR: "server_error",
  ENTITY_SAVED: "entity_saved",
  NEWSLETTER_SUBSCRIBE: "newsletter_subscribe",
  BACK_IN_STOCK_SUCCESS: "back_in_stock_success",
  GOOGLE_RECAPTCHA_ERROR: "google_recaptcha_error",
  CUSTOMER_ADDRESS_UPDATED: "customer_address_updated",
  CUSTOMER_ADDRESS_CREATED: "customer_address_created",
  CUSTOMER_DISABLED: "customer_disabled",
  OUT_OF_STOCK: "out_of_stock",
} as const

export const ALERT_PRESETS = objectValues(ALERT_CODES)

export type AlertPreset = ObjectValues<typeof ALERT_CODES>

export type AlertCodesValues = (typeof ALERT_CODES)[keyof typeof ALERT_CODES]
export type AlertCodesKeys = keyof typeof ALERT_CODES
