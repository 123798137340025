import { i18nConfig } from "~/config/i18n-config"
import { getDefaultCountry, getDefaultI18n, getDefaultLocale } from "~/lib/i18n/utils/get-default"
import { objectKeys } from "~/utils/object-keys"

export const LOCALES = objectKeys(i18nConfig)
export const DEFAULT_I18N = getDefaultI18n()
export const DEFAULT_LOCALE = getDefaultLocale()

export const DEFAULT_MARKET = DEFAULT_I18N.market
export const DEFAULT_LANG = DEFAULT_I18N.lang

export const DEFAULT_CONTRY = getDefaultCountry(DEFAULT_MARKET)
